@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');
@font-face {
  font-family: 'VAG Rounded';
  src: url('./resources/fonts/VAGRounded-Black.woff2') format('woff2'),
      url('./resources/fonts/VAGRounded-Black.woff') format('woff');
  font-style: normal;
  font-display: swap;
}

*:focus {
  outline: none!important;
}

body {
  overflow-x: hidden;
  background-color: #FFFFFF;
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1200 800'%3E%3Cdefs%3E%3CradialGradient id='a' cx='0' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23002846'/%3E%3Cstop offset='1' stop-color='%23002846' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='b' cx='1200' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23003d61'/%3E%3Cstop offset='1' stop-color='%23003d61' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='c' cx='600' cy='0' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23003d61'/%3E%3Cstop offset='1' stop-color='%23003d61' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='d' cx='600' cy='800' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23002846'/%3E%3Cstop offset='1' stop-color='%23002846' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='e' cx='0' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23002846'/%3E%3Cstop offset='1' stop-color='%23002846' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='f' cx='1200' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%2300567C'/%3E%3Cstop offset='1' stop-color='%2300567C' stop-opacity='0'/%3E%3C/radialGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='1200' height='800'/%3E%3Crect fill='url(%23b)' width='1200' height='800'/%3E%3Crect fill='url(%23c)' width='1200' height='800'/%3E%3Crect fill='url(%23d)' width='1200' height='800'/%3E%3Crect fill='url(%23e)' width='1200' height='800'/%3E%3Crect fill='url(%23f)' width='1200' height='800'/%3E%3C/svg%3E"); */
  background-attachment: fixed;
  background-size: cover;
  color: '#fff'
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
}

h1 {
  font-family: 'VAG Rounded';
}


.app_logo {
    height: 75px;
    width: auto;
    margin-top: 10px;
}

.app_logo_hero {
  width: 25%;
}

.hero-image {
  max-width: 70%;
}

.rates-image {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 45%;
}

.about-image {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 45%;
}

.indicator .active {
  background-color: #e41f26;
}

.circle_icon {
  width: 60%;
  filter: invert(100%);
}

.circle_logo {
  width: 65%;
}

.straight_logo {
  width: 35%;
}

.apple_play_img {
  width: 50%;
}

.google_play_img {
  width: 50%;
}

.image_ {
  width: 30%;
  display: block;
  margin: 0 auto;
}

.response_img {
  display: block;
  margin: 0 auto;
  width: 50%;
}

/* animated items */
.circle_red {
  width: 35px;
  height: 35px;
  border-radius: 17.5px;
  background-color: #e41f26;
}
.pulsating-circle {
  position: absolute;
  right: -40px;
  top: 5px;
  transform: translateX(-50%) translateY(-50%);
  width: 40px;
  height: 40px;
}
.pulsating-circle:before {
  content: "";
  position: relative;
  display: block;
  width: 300%;
  height: 300%;
  box-sizing: border-box;
  margin-left: -100%;
  margin-top: -100%;
  border-radius: 60px;
  background-color: #e41f26;
  -webkit-animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
          animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}
.pulsating-circle:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #e41f26;
  border-radius: 20px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  -webkit-animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
          animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}

@-webkit-keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%, 100% {
    opacity: 0;
  }
}
@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%, 100% {
    opacity: 0;
  }
}
@-webkit-keyframes pulse-dot {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}
@keyframes pulse-dot {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}

/* xs */
@media screen and (max-width: 599px) {
  .rates-image {
    position: relative;
    top: 0;
    left: 0;
    max-width: 85%;
  }
  .about-image {
    position: relative;
    top: 0;
    right: 0;
    max-width: 85%;
  }
  .app_logo {
    width: 30%;
    height: auto;
  }
}

/* sm */
@media screen and (max-width: 899px) {
  .rates-image {
    position: relative;
    top: 0;
    left: 0;
    max-width: 65%;
  }
  .about-image {
    position: relative;
    top: 0;
    right: 0;
    max-width: 65%;
  }
  .app_logo {
    width: 30%;
    height: auto;
  }
}

/* md */
@media screen and (max-width: 1199px) {
}


/* lg */
@media screen and (max-width: 1535px) {
}




